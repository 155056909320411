@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    scroll-margin-top: 40px;
}

.highlight {
    background: #faaf0033;
    color: #121212;
}

.highlight-focus {
    background: #faaf0066 !important;
}

#learning-outcomes-area p {
    scroll-margin-top: 80px;
}

.dragClass {
    opacity: 1 !important;
    background: #ffffce !important;
}

.tiptap p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

@layer components {
    .text-editor .is-active {
        @apply !bg-primary-main text-neutral-10 border-primary-main;
    }
}

.reset,
.reset * {
    all: unset;
}

.dynamic-badge {
    background: var(--bg-dynamic-badge);
}
.dynamic-badge:hover {
    background: color-mix(in srgb, var(--bg-dynamic-badge) 100%, #000 5%);
}
