body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #d9d9d9;
}

.table-header th:first-child {
  border-top-left-radius: 8px;
}
.table-header th:last-child {
  border-top-right-radius: 8px;
}

.table-finding td {
  @apply px-4 py-2 border border-l-0 border-r-0 border-neutral-20;
}
.table-finding thead {
  @apply bg-neutral-20;
}

#schedule_table {
  @apply w-full border table-fixed border-neutral-40;
}

#schedule_table thead td:first-child,
#schedule_table tbody td:first-child {
  @apply w-[80px] px-2.5 align-top text-right text-sm whitespace-nowrap overflow-hidden;
}

#schedule_table thead td {
  @apply h-[40px] text-center align-middle bg-neutral-10 border border-b-2 border-neutral-40;
}

#schedule_table tbody td {
  @apply h-[40px] border-x border-neutral-40;
  border-bottom: 2px dotted #ededed;
}

#bulk_import_users {
  @apply table-fixed border border-neutral-30 min-w-max;
}

#bulk_import_users thead td {
  @apply py-1 px-2.5 text-sm font-medium text-neutral-101 bg-neutral-20 border border-neutral-30;
}

#bulk_import_users tbody td {
  @apply py-1 px-2.5 text-sm text-neutral-90 border border-neutral-30;
}

.animate-pulse-sm {
  animation: pulse-sm 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse-sm {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
}

.chess-pattern::before {
  content: "";
  position: absolute;
  inset: 0;
}
.chess-pattern::after {
  content: "";
  position: absolute;
  inset: 0;
}

.chess-pattern-error::before {
  background: repeating-linear-gradient(0deg, #f0d5d1 0, #f0d5d1 2px, #fff 2px, #fff 4px);
}
.chess-pattern-error::after {
  background: repeating-linear-gradient(90deg, #f0d5d1 0, #f0d5d1 2px, #fff 2px, #fff 4px);
  mix-blend-mode: darken;
}

.chess-pattern-success::before {
  background: repeating-linear-gradient(0deg, #ceeede 0, #ceeede 2px, #fff 2px, #fff 4px);
}
.chess-pattern-success::after {
  background: repeating-linear-gradient(90deg, #ceeede 0, #ceeede 2px, #fff 2px, #fff 4px);
  mix-blend-mode: darken;
}

.chess-pattern > * {
  position: relative;
  z-index: 1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the number input arrow in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
